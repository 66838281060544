<!--
    Copyright (C) Octillion Media LLC 2019
    All Rights Reserved.
-->

<template>
    <b-badge class="font-size-12"  :variant="getStatusVariant(localItem.status)">{{ getStatusText(localItem.status) }}</b-badge>
    <b-button v-if="customFlag" :disabled="localItem.status!==2" @click="downloadFile(localItem.url)">
    <i class="bx bx-download font-size-20 cursor-pointer primary-color" > </i>
    </b-button>
</template>

<script>
import { watch } from 'vue';
import { useSubscription } from "@vue/apollo-composable";
import gql from 'graphql-tag';

export default {
    props: {
        id: {
            type: Number,
            default: 0,
        },
      customFlag: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: null,
      },
    },
    mounted() {
        const { result } = useSubscription(gql`
        subscription {
            jobStatus(id:"downloads${process.env.NODE_ENV === 'production' ? '' : '-staging' }_${this.id.toString()}") {
                id, message, url, status
            }
        }
        `)
        watch(
            result, 
            data => {  
                if (data !== undefined) {
                   console.log('GQL DATA:', data.jobStatus)
                    this.localItem.status= this.getStatusFromText(data.jobStatus.message)
                    if(this.localItem.status===2){
                      this.localItem.url=data.jobStatus.url
                    }

                }
            }, 
            { lazy: true }
        )
    },
    data() {
        return {
          localItem: { ...this.item },
        }
    },
  methods: {
    downloadFile(url) {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank'; // Opens in a new tab
      anchor.download = ''; // Triggers the download
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor); // Clean up
    },
    getStatusVariant(status) {
      const variants = {
        0: "primary",    // New
        1: "warning",    // Processing
        2: "success",    // Completed
        3: "danger",     // Failed
      };
      return variants[status] || "secondary";
    },
    getStatusText(status) {
      const statuses = {
        0: "New",
        1: "Processing",
        2: "Completed",
        3: "Failed",
      };
      return statuses[status] || "Processing data...";
    },
    getStatusFromText(text) {
  const statusMap = {
    "New": 0,
    "Processing": 1,
    "Completed": 2,
    "Failed": 3,
  };

  return statusMap[text] ?? -1; // Return -1 if the text doesn't match any key
}
  }
}
</script>
