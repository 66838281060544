/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import { mapState, mapGetters, mapActions} from 'vuex'

export const authComputed = {
    ...mapState('auth', {
        currentUser: (state) => state.user,
        loginStatus: (state) => state.loginStatus,
        checkEmailExistForgetPassword: (state) => state.checkEmailExistForgetPassword,
        forgetPassRequestSuccess: (state) => state.forgetPassRequestSuccess,
        forgetPassRequestErr: (state) => state.forgetPassRequestErr,
        updateForgetPasswordSuccess: (state) => state.updateForgetPasswordSuccess,
        updateForgetPasswordErr: (state) => state.updateForgetPasswordErr,
    }),
    ...mapGetters('auth', ['loggedIn']),
}
export const authMethods = mapActions('auth', ['logIn',
    'logout',
    'register',
    'resetPassword',
    'signIn',
    'switchAcct',
    'checkEmailExistForgetPassFunc',
    'requestResetPassword',
    'clearForgetPassword'
])

export const layoutComputed = {
    ...mapState('layout', {
        layoutType: (state) => state.layoutType,
        leftSidebarType: (state) => state.leftSidebarType,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar,
        loader: (state) => state.loader
    })
}


export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])


export const topNavComputed = {
    ...mapState('navbar', {
        dateFilter: (state) => state.dateFilter,
        isCompare: (state) => state.isCompare,
        headerTitle: (state) => state.headerTitle
    })
}
export const topNavMethods = mapActions('navbar', ['setTitle', 'updateDateRange', 'setCompare']);


export const notificationComputed = {
    ...mapState('notification', {
        searchResult: (state) => state.searchResult,
    })
}
export const notificationMethods = mapActions('notification', ['success', 'error', 'clear', 'fetchSearchResult', 'clearSearchResult'])

export const activityLogComputed = {
    ...mapState('activitylog', {
        activityLogList: (state) => state.activityLogList
    })
}
export const activityLogMethods = mapActions('activitylog', ['getActivityLog', 'clearActivityLog'])

export const presetsComputed = {
    ...mapState('presets', {
        presetSuccess: state => state.presetSuccess,
        presetError: state => state.presetError,
        presetList: state => state.presetList,
        segmentGroupList: state => state.segmentGroupList,
        presetLineItemList: state => state.presetLineItemList,
        presetSingleData: state => state.presetSingleData,
        presetDeleteStatus: state => state.presetDeleteStatus,
        presetDeliveryModiferList: state => state.presetDeliveryModiferList,
        presetDeliveryCappingList: state => state.presetDeliveryCappingList,
        presetBidModiferList: state => state.presetBidModiferList
    })
}
export const presetMethods = mapActions('presets', ['postPreset',
    'putPreset',
    'clearPreset',
    'clearSegmentGroupPreset',
    'getPresets',
    'exportPresetsRecord',
    'deletePreset',
    'getSinglePreset',
    'clearDeletePreset',
    'clearPutPreset'
])


// accounts Section
export const accountComputed = {
    ...mapState('account', {
        accounts: (state) => state.accounts,
        accountResponses: state => state.accountResponses,
        accountErr: (state) => state.accountErr,
        accountSuccess: (state) => state.accountSuccess,
        accountSingleData: state => state.accountSingleData,
        accountDeleteStatus: (state) => state.accountDeleteStatus,
    })
}
export const accountMethods = mapActions('account', [
    'fetchAccounts',
    'fetchSingleAccount',
    'clearDeleteAccount',
    'putAccount',
    'postAccount',
    'postFullAccount',
    'putFullAccount',
    'fetchFullSingleAccount',
    'deleteAccount',
    'clearAccount'])


// advertiser Section
export const advertiserComputed = {
    ...mapState('advertiser', {
        advertisers: (state) => state.advertisers,
        advertisersDownload: (state) => state.advertisersDownload,
        advertisersListData: (state) => state.advertisersListData,
        advertiserCategories: (state) => state.advertiserCategories,
        advertiserSucccess: (state) => state.advertiserSucccess,
        advertiserSingleData: state => state.advertiserSingleData,
        advertiserExists: (state) => state.advertiserExists,
        advertiserDomainExists: (state) => state.advertiserDomainExists,
        advertiserErr: (state) => state.advertiserErr,
        advertiserDeleteStatus: (state) => state.advertiserDeleteStatus,
        advertiserSucccessWithoutRefresh: (state) => state.advertiserSucccessWithoutRefresh,
    })
}
export const advertiserMethods = mapActions('advertiser', ['fetchAdvertisers',
    'fetchAdvertiserCategories',
    'postAdvertiser',
    'postFavoriteAdvertiser',
    'putAdvertiser',
    'deleteAdvertiser',
    'clearDeleteAdvertiser',
    'deleteFavoriteAdvertiser',
    'isAdvertisersExists',
    'isAdvertisersDomainExists',
    'fetchSingleAdvertisers',
    'clearIsAdvertiserExist',
    'clearIsAdvertiserDomainExist',
    'exportAdvertiserRecord',
    'clearAdvertiser'])


export const pixelComputed = {
    ...mapState('pixel', {
        pixels: (state) => state.pixels,
        pixelPages: (state) => state.pixelPages
    })
}
export const pixelMethods = mapActions('pixel', [
    'fetchPixels',
    'fetchPixelPages'
])


// manufacturer Section
export const manufacturerComputed = {
    ...mapState('manufacturer', {
        manufacturers: (state) => state.manufacturers,
        manufacturersDownload: (state) => state.manufacturersDownload,
        manufacturersListData: (state) => state.manufacturersListData,
        manufacturerCategories: (state) => state.manufacturerCategories,
        manufacturerSucccess: (state) => state.manufacturerSucccess,
        manufacturerSingleData: state => state.manufacturerSingleData,
        manufacturerExists: (state) => state.manufacturerExists,
        manufacturerDomainExists: (state) => state.manufacturerDomainExists,
        manufacturerErr: (state) => state.manufacturerErr,
        manufacturerDeleteStatus: (state) => state.manufacturerDeleteStatus,
        manufacturerSucccessWithoutRefresh: (state) => state.manufacturerSucccessWithoutRefresh,
    })
}
export const manufacturerMethods = mapActions('manufacturer', ['fetchManufacturers',
    'postManufacturer',
    'putManufacturer',
    'deleteManufacturer',
    'clearDeleteManufacturer',
    'isManufacturersExists',
    'isManufacturersDomainExists',
    'fetchSingleManufacturers',
    'clearIsManufacturerExist',
    'clearIsManufacturerDomainExist',
    'exportManufacturerRecord',
    'clearManufacturer'])


// manufacturer Section
export const parentcompanyComputed = {
    ...mapState('parentcompany', {
        parentCompanies: state => state.parentCompanies,
        parentCompanyListData: state => state.parentCompanyListData,
        parentCompanySucccess: state => state.parentCompanySucccess,
        parentCompanySucccessWithoutRefresh: state => state.parentCompanySucccessWithoutRefresh,
        parentCompanySingleData: state => state.parentCompanySingleData,
        parentCompanyExists: state => state.parentCompanyExists,
        parentCompanyDomainExists: state => state.parentCompanyDomainExists,
        parentCompanyErr: state => state.parentCompanyErr,
        parentCompanyDeleteStatus: state => state.parentCompanyDeleteStatus,
        parentCompanyDownload: state => state.parentCompanyDownload,
    })
}
export const parentcompanyMethods = mapActions('parentcompany', ['fetchParentCompanies',
    'postParentCompany',
    'putParentCompany',
    'deleteParentCompany',
    'clearDeleteParentCompany',
    'isParentCompanyExists',
    'isParentCompanyDomainExists',
    'fetchSingleParentCompany',
    'clearIsParentCompanyExist',
    'clearIsParentCompanyDomainExist',
    'exportParentCompanyRecord',
    'clearParentCompany'])

// buyingGroup Section
export const buyingGroupComputed = {
    ...mapState('buyinggroup', {
        buyingGroupSingleData: state => state.buyingGroupSingleData,
        buying_groups: state => state.buying_groups,
        buyingGroupDeleteStatus: (state) => state.buyingGroupDeleteStatus,
        buyingGroupExists: (state) => state.buyingGroupExists,
        buyingGroupDomainExists: (state) => state.buyingGroupDomainExists,
        buyingGroupSucccess: (state) => state.buyingGroupSucccess,
        buyingGroupSuccessWithRefresh: (state) => state.buyingGroupSuccessWithRefresh,
        buyingGroupErr: (state) => state.buyingGroupErr,
        buyingGroupDownload: (state) => state.buyingGroupDownload
    })
}
export const buyingGroupMethods = mapActions('buyinggroup', [
    'fetchBuyingGroups',
    'fetchSingleBuyingGroup',
    'isBuyingGroupExists',
    'isBuyingGroupDomainExist',
    'postBuyingGroup',
    'putBuyingGroup',
    'deleteBuyingGroup',
    'clearIsBuyingGroupsExist',
    'clearIsBuyingGroupsDomainExist',
    'clearBuyingGroups',
    'exportBuyingGroupRecord'])

// roles Section
export const rolesComputed = {
    ...mapState('role', {
        roles: (state) => state.roles,
        roleSuccess: (state) => state.roleSuccess,
        roleErr: (state) => state.roleErr,
        roleSingleData: (state) => state.roleSingleData,
        rolesDeleteStatus: (state) => state.rolesDeleteStatus,
    })
}
export const rolesMethods = mapActions('role', ['fetchRoles', 'putRoles', 'getSingleRole', 'postRoles', 'clearRole', 'deleteRoles', 'clearDeleteRole'
])
//Add-ons
export const addOnsComputed = {
    ...mapState('addon', {
        addOns: (state) => state.addOns,
        addOnSuccess: (state) => state.addOnSuccess,
        addOnsDeleteStatus: (state) => state.addOnsDeleteStatus,
        addOnErr: state => state.addOnErr,
        addOnSingleData: state => state.addOnSingleData,

    })
}
export const addOnsMethods = mapActions('addon', ['fetchAddOns', 'postAddOn', 'clearAddOn', 'fetchSingleAddOn', 'putAddOn', 'deleteAddOn', 'clearAddOnList'
])
//Deals
export const dealsComputed = {
    ...mapState('deal', {
        deals: (state) => state.deals,
        dealSuccess: (state) => state.dealSuccess,
        dealsDeleteStatus: (state) => state.dealsDeleteStatus,
        dealErr: state => state.dealErr,
        dealSingleData: state => state.dealSingleData,
        dealExists: state => state.dealExists,
        dealPutSuccess: state => state.dealPutSuccess,
        dealsDownload: state => state.dealsDownload,

    })
}
export const dealsMethods = mapActions('deal', [
    'fetchDeals',
    'postDeal', 'clearDeal','clearIsDealExist',
    'fetchSingleDeal', 'putDeal',
    'deleteDeal', 'isDealExist', 'exportDealsRecord'
])
//Lists
export const listsComputed = {
    ...mapState('list', {
        lists: (state) => state.lists,
        listSuccess: (state) => state.listSuccess,
        listsDeleteStatus: (state) => state.listsDeleteStatus,
        listErr: state => state.listErr,
        listSingleData: state => state.listSingleData,
    })
}
export const listsMethods = mapActions('list', ['fetchLists', 'postList', 'clearList', 'fetchSingleList', 'putList', 'deleteList', 'clearList'
])

//List Items
export const listItemsComputed = {
    ...mapState('listitems', {
        listitems: (state) => state.listitems,
        listitemSuccess: (state) => state.listitemSuccess,
        listitemsDeleteStatus: (state) => state.listitemsDeleteStatus,
        listitemErr: state => state.listitemErr,
        listItemSingleData: state => state.listItemSingleData,
        allListItemsDeleteStatus: state => state.allListItemsDeleteStatus,
        listItemSuccessWithoutRefresh: state => state.listItemSuccessWithoutRefresh,
        listItemUpdateWithoutRefresh: state => state.listItemUpdateWithoutRefresh,
        customListItemsDownload: state => state.customListItemsDownload,
    })
}
export const listItemsMethods = mapActions('listitems',
    [
        'fetchListItems',
        'postListItem',
        'clearListItems',
        'fetchSingleListItem',
        'putListItem',
        'deleteListItem',
        'clearListItems',
        'deleteAllListItems',
        'clearAllDeleteListItems',
        'clearListItemErr',
        'exportCustomListItemRecord'
    ])


//Users
export const usersComputed = {
    ...mapState('users', {
        users: (state) => state.users,
        userSuccess: (state) => state.userSuccess,
        usersDeleteStatus: (state) => state.usersDeleteStatus,
        userErr: state => state.userErr,
        userSingleData: state => state.userSingleData,
        emailExists: state => state.emailExists,
        domainNotAllow: state => state.domainNotAllow,
        usersDownload: (state) => state.usersDownload,

    })
}
export const usersMethods = mapActions('users', ['fetchUsers',
    'postUser',
    'clearUser',
    'fetchSingleUser',
    'putUser',
    'putUserProfile',
    'deleteUser',
    'isEmailExists',
    'clearIsEamilExist',
    'clearUserErr',
    'clearDeleteUser',
    'exportUsersRecord'
])

export const statisticsComputed = {
    ...mapState('statistics', {
        widgetData: (state) => state.widgetData,
        statFields: (state) => state.statFields,
        statFieldsSuccess: state => state.statFieldsSuccess,
    })
}

export const statisticsMethods = mapActions('statistics', [
    'fetchWidgetData',
    'getStatField',
    'postStatField',
    'clearStatField',
    'putStatField',
    'clearWidgetData'
])

// campaign Section
export const campaignComputed = {
    ...mapState('campaign', {
        campaigns: (state) => state.campaigns,
        campaignSuccess: (state) => state.campaignSuccess,
        campaignFindAndReplace: (state) => state.campaignFindAndReplace,
        campaignFindAndReplaceErr: (state) => state.campaignFindAndReplaceErr,
        campaignErr: (state) => state.campaignErr,
        vendorFeesSuccess: (state) => state.vendorFeesSuccess,
        vendorCreateFeesSuccess: (state) => state.vendorCreateFeesSuccess,
        campiagnVendorFeesSuccess: (state) => state.campiagnVendorFeesSuccess,
        campiagnBudgetFlightSuccess: (state) => state.campiagnBudgetFlightSuccess,
        campaignDeleteStatus: state => state.campaignDeleteStatus,
        campaignBulkDeleteStatus: state => state.campaignBulkDeleteStatus,
        campaignSingleData: state => state.campaignSingleData,
        campaignExist: state => state.campaignExist,
        campaignSuccessWithoutRefresh: (state) => state.campaignSuccessWithoutRefresh,
        campaignsDownload: (state) => state.campaignsDownload,
        budgetFlightsDownload: state => state.budgetFlightsDownload,
        campaignsSummary: (state) => state.campaignsSummary,
        importCampaignLineItemSuccess: (state) => state.importCampaignLineItemSuccess,
        importCampaignLineItemErr: (state) => state.importCampaignLineItemErr,
        flightLogsData: state => state.flightLogsData

    })
}
export const campaignMethods = mapActions('campaign', [
    'fetchCampaigns',
    'fetchCampaignSummary',
    'fetchSingleCampaign',
    'postCampaign',
    'postCampaignFindAndReplace',
    'postImportCampaignLineItem',
    'clearImportCampaignLineItem',
    'fetchVendorFees',
    'deleteCampaign',
    'deleteBulkCampaign',
    'clearDeleteCampaign',
    'putCampaign',
    'putStrictCampaign',
    'postCampaignMultipleVendorFeeApi',
    'newCampaignVendorFees',
    'putCampaignVendorFees',
    'deleteCampaignVendorFees',
    'clearCampaign',
    'clearCampaignList',
    'fetchCampaignVendorFees',
    'clearVendorFees',
    'clearIsCampaignExist',
    'isCampaignExist',
    'duplicateCampaign',
    'exportCampaignRecord',
    'exportBudgetFlightsRecord',
    'fetchCampaignBudgetFlight',
    'clearBudgetFlight',
    'getFlightActivityLog',
	'clearFlightActivityLog'
]);

// creatives Section
export const creativeComputed = {
    ...mapState('creative', {
        creatives: (state) => state.creatives,
        creativeSuccess: (state) => state.creativeSuccess,
        creativeExists: (state) => state.creativeExists,
        creativeSuccessPost: (state) => state.creativeSuccessPost,
        videoSuccessPost: (state) => state.videoSuccessPost,
        creativeErr: (state) => state.creativeErr,
        creativeDeleteStatus: state => state.creativeDeleteStatus,
        creativeBulkDeleteStatus: state => state.creativeBulkDeleteStatus,
        creativeSingleData: state => state.creativeSingleData,
        existingAssets: state => state.existingAssets,
        existingCompanionAssets: state => state.existingCompanionAssets,
        existingBannerAssets: state => state.existingBannerAssets,
        existingAudioAssets: state => state.existingAudioAssets,
        creativeAddOn: state => state.creativeAddOn,
        creativeFindAndReplace: (state) => state.creativeFindAndReplace,
        creativeFindAndReplaceErr: (state) => state.creativeFindAndReplaceErr,
        creativeSuccessPut: (state) => state.creativeSuccessPut,
        creativeApprovalQueues: (state) => state.creativeApprovalQueues,
        creativeApprovalHistory: (state) => state.creativeApprovalHistory,
        creativeSuccessWithoutRefresh: (state) => state.creativeSuccessWithoutRefresh,
        creativesDownload: (state) => state.creativesDownload,
        creativeUrlAssetSuccess: (state) => state.creativeUrlAssetSuccess,
        singleCreativeUrlAsset: (state) => state.singleCreativeUrlAsset,
        creativeUrlAssetSuccessPut: (state) => state.creativeUrlAssetSuccessPut,
    })
}
export const creativeMethods = mapActions('creative', ['fetchCreatives',
    'fetchSingleCreative',
    'fetchCreativeApprovalQueues',
    'fetchCreativeApprovalHistories',
    'postCreative',
    'duplicateCreative',
    'postCreativeVideo',
    'deleteCreative',
    'deleteBulkCreative',
    'putCreative',
    'clearCreative',
    'clearDeleteCreative',
    'clearCreativeList',
    'fetchExistingAssets',
    'fetchExistingCompanionAssets',
    'fetchExistingBannerAssets',
    'fetchExistingAudioAssets',
    'fetchCreativeAddOn',
    'postCreativeFindAndReplace',
    'clearExistingAssets',
    'clearExistingAudioAssets',
    'clearExistingCompanionAssets',
    'clearExistingBannerAssets',
    'isCreativeExists',
    'clearIsCreativeExist',
    'exportCreativeRecord',
    'clearCreativeSuccessWithoutRefresh',
    'postCreativeUrlAsset',
    'putCreativeUrlAsset',
    'fetchSingleCreativeUrlAsset'
]);


// creative Segments

export const creativeSegmentsComputed = {
    ...mapState('creativesegment', {
        creativeSegments: (state) => state.creativeSegments,
        creativeSegmentsErr: (state) => state.creativeSegmentsErr,
    })
}
export const creativeSegmentsMethods = mapActions('creativesegment', ['fetchCreativeSegments', 'clearCreativeSegments']);


// dashboard api

export const dashboardComputed = {
    ...mapState('dashboard', {
        advertisersStats: (state) => state.advertisersStats,
        creativesStats: (state) => state.creativesStats,
        dashboardStats: state => state.dashboardStats,
        downloadDashboardStats: state => state.downloadDashboardStats,
        daypartStats: (state) => state.daypartStats,
        providersStats: (state) => state.providersStats,
        videoStats: (state) => state.videoStats,
        devicesStats: (state) => state.devicesStats,
        geoStats: (state) => state.geoStats,
        geoHitMapStats: state => state.geoHitMapStats,
        campaignStats: state => state.campaignStats,
        lineItemsStats: state => state.lineItemsStats,
        audienceStats: (state) => state.audienceStats,
        campaignPerformaceStats: (state) => state.campaignPerformaceStats
    })
}

export const dashboardMethods = mapActions('dashboard', [
    'fetchDashboardStats',
    'downloadDashboardsStats'
]);


export const retargetingSegmentsComputed = {
    ...mapState('retargetingsegment', {
        retargetingSegments: (state) => state.retargetingSegments,
        retargetingSegmentSuccess: (state) => state.retargetingSegmentSuccess,
        retargetingSegmentErr: (state) => state.retargetingSegmentErr,
    })
}
export const retargetingSegmentsMethods = mapActions('retargetingsegment', [
    'fetchRetargetingSegments',
    'postRetargetingSegment',
    'clearRetargetingSegments',
    'putRetargetingSegment'
]);


// line items Section
export const lineItemComputed = {
    ...mapState('lineitem', {
        saveQueryParamsSuccess: state => state.saveQueryParamsSuccess,
        getQueryParamsSuccess: state => state.getQueryParamsSuccess,
        lineitemSuccess: (state) => state.lineitemSuccess,
        lineitemListSuccess: (state) => state.lineitemListSuccess,
        lineitemTargetingSuccess: (state) => state.lineitemTargetingSuccess,
        lineitemTargetingErr: (state) => state.lineitemTargetingErr,
        lineitemUpdateSuccess: (state) => state.lineitemUpdateSuccess,
        lineitemErr: (state) => state.lineitemErr,
        appIdsData: (state) => state.appIdsData,
        appNameData: (state) => state.appNameData,
        appNameResponse: (state) => state.appNameResponse,
        appIdResponse: (state) => state.appIdResponse,
        appBundleResponse: (state) => state.appBundleResponse,
        providerData: (state) => state.providerData,
        providerListResponse: (state) => state.providerListResponse,
        channelListResponse: (state) => state.channelListResponse,
        appNameListResponse: (state) => state.appNameListResponse,
        dealIdsData: state => state.dealIdsData,
        appBundleData: state => state.appBundleData,
        placementData: state => state.placementData,
        publisherIdsData: state => state.publisherIdsData,
        countriesData: state => state.countriesData,
        permissionsData: state => state.permissionsData,
        regionsData: state => state.regionsData,
        metrosData: state => state.metrosData,
        osTypesData: state => state.osTypesData,
        timeZoneData: state => state.timeZoneData,
        citiesData: state => state.citiesData,
        inventorySourceData: state => state.inventorySourceData,
        languagesData: state => state.languagesData,
        contentRatingData: state => state.contentRatingData,
        contentCategoriesData: state => state.contentCategoriesData,
        genreData: state => state.genreData,
        zipCodeData: state => state.zipCodeData,
        latlongData: state => state.latlongData,
        adPositionData: state => state.adPositionData,
        environmentTypeData: state => state.environmentTypeData,
        nativeLayoutData: state => state.nativeLayoutData,
        advertiserCreativeSuccess: (state) => state.advertiserCreativeSuccess,
        creativeSuccess: (state) => state.creativeSuccess,
        bulkCreativeSuccess: (state) => state.bulkCreativeSuccess,
        creativeListSuccess: (state) => state.creativeListSuccess,
        lineItemDeleteStatus: state => state.lineItemDeleteStatus,
        lineItemBulkDeleteStatus: state => state.lineItemBulkDeleteStatus,
        assLineItemDeleteStatus: state => state.assLineItemDeleteStatus,
        lineItemFindAndReplace: (state) => state.lineItemFindAndReplace,
        lineItemFindAndReplaceErr: (state) => state.lineItemFindAndReplaceErr,
        creativeLineItemFindAndReplaceErr: (state) => state.creativeLineItemFindAndReplaceErr,
        lineItemSingleData: state => state.lineItemSingleData,
        lineitemTargetingSingleSuccess: state => state.lineitemTargetingSingleSuccess,
        lineItemOurSegmentSuccess: state => state.lineItemOurSegmentSuccess,
        lineItemThirdpartySegmentSuccess: state => state.lineItemThirdpartySegmentSuccess,
        lineItemAssCreativeUpdateStatus: state => state.lineItemAssCreativeUpdateStatus,
        creativeLineItemFindAndReplace: state => state.creativeLineItemFindAndReplace,
        lineItemNameExist: state => state.lineItemNameExist,
        duplicateLineitemSuccess: state => state.duplicateLineitemSuccess,
        lineItemThirdpartySelectedSegmentSuccess: state => state.lineItemThirdpartySelectedSegmentSuccess,
        isLineItemLoad: state => state.isLineItemLoad,
        isExchangeRequired: (state) => state.isExchangeRequired,
        lineItemRealTimeStat: state => state.lineItemRealTimeStat,
        lineItemSuccessWithoutRefresh: state => state.lineItemSuccessWithoutRefresh,
        creativeLineItemListSuccess: state => state.creativeLineItemListSuccess,
        lineItemsDownload: state => state.lineItemsDownload,
        lineItemDuplicateSwitch: state => state.lineItemDuplicateSwitch,
        lineItemTargetListDataFlag: state => state.lineItemTargetListDataFlag,
        lineItemBiddingStatFlags: state => state.lineItemBiddingStatFlags,
        lineItemBulkTargetingStatus: state => state.lineItemBulkTargetingStatus,
        assocLineItemCreativeBulkDeleteStatus: state => state.assocLineItemCreativeBulkDeleteStatus,
        lineitemActiveSuccess: state => state.lineitemActiveSuccess,
        lineitemActiveErr: state => state.lineitemActiveErr,
        bidModifierSuccess: state => state.bidModifierSuccess,
        rillDataSuccess: state => state.rillDataSuccess,
        bidModifierError: state => state.bidModifierError,
        bidModifierData: state => state.bidModifierData,
        bidModifierSingleData: state => state.bidModifierSingleData,
        deliveryModifierSuccess: state => state.deliveryModifierSuccess,
        deliveryModifierBulkResponse: state => state.deliveryModifierBulkResponse,
        deliveryCappingBulkResponse: state => state.deliveryCappingBulkResponse,
        deliveryModifierError: state => state.deliveryModifierError,
        deliveryModifierData: state => state.deliveryModifierData,
        deliveryModifierSingleData: state => state.deliveryModifierSingleData,
        deliveryCappingsSuccess: state => state.deliveryCappingsSuccess,
        deliveryCappingsError: state => state.deliveryCappingsError,
        deliveryCappingsData: state => state.deliveryCappingsData,
        deliveryCappingsBulkResponse: state => state.deliveryCappingsBulkResponse,
        bidModifierBulkResponse: state => state.bidModifierBulkResponse,
        deliveryCappingsSingleData: state => state.deliveryCappingsSingleData,
    })
}
export const lineItemMethods = mapActions('lineitem', [
    'postSaveQueryParams',
    'getQueryParamsApi',
    'postLineitem',
    'postStrictLineitem',
    'duplicateLineitem',
    'postLineitemTargeting',
    'postLineItemFindAndReplace',
    'getCustomList',
    'getAppNameLookup',
    'getAppLookupField',
    'fetchProviders',
    'setLineItemLoad',
    'getCustomRef',
    'fetchAdvertiserCreative',
    'fetchCampaignLineItem',
    'postCreativeLineitem',
    'postBulkCreativeLineitem',
    'getLineitemCreativeList',
    'putLineitem',
    'putStrictLineItem',
    'deleteLineItem',
    'deleteBulkLineItem',
    'clearDeleteLineItemState',
    'clearLineitemWithoutRefresh',
    'clearLineitem',
    'clearLineitemErr',
    'clearLineitemTarget',
    'clearLineitemSuccess',
    'clearDuplicateLineitemSuccess',
    'putLineitemTargeting',
    'getLineitemTargeting',
    'deleteAssLineItem',
    'fetchSingleLineItem',
    'getLineitemOurSegmentList',
    'clearLineItemSegment',
    'getLineitemThirdpartySegmentSearchList',
    'getLineitemThirdpartySegmentList',
    'putAssLineItem',
    'clearDeleteAssLineItem',
    'postCreativeLineItemFindAndReplace',
    'clearDeleteAssLineItem',
    'clearIsLineItemNameExist',
    'isLineItemNameExist',
    'clearAssCreativeFindAndReplace',
    'clearMetaData',
    'getRealTimeStat',
    'setIsExchangeRequired',
    'exportLineItemRecord',
    'checkLineItemDuplicateSwitch',
    'clearLineItemTargetListData',
    'fetchBiddingStatFlags',
    'postBulkTargetingLineItem',
    'deleteBulkAssLineItemCreative',
    'clearBulkTargetingLineItemStatus',
    'putStrictLineItemActive',
    'clearStrictLineItemActive',
    'clearDeleteLineItemCreativeState',
    'postBidModifier',
    'putBidModifier',
    'getBidModifier',
    'clearModifierData',
    'getSingleBidModifier',
    'postDeliveryModifier',
    'putDeliveryModifier',
    'postDeliveryBulkModifier',
    'postDeliveryBulkCapping',
    'getDeliveryModifier',
    'clearDeliveryModifierData',
    'getSingleDeliveryModifier',
    'clearDeliveryCappingsData',
    'clearBidModifierData',
    'getSingleDeliveryCappings',
    'postDeliveryCappings',
    'putDeliveryCappings',
    'postDeliveryBulkCappings',
    'postBulkBidModifier',
    'getDeliveryCappings',
    'fetchRillData'
])

//Vendor Methods
export const vendorComputed = {
    ...mapState('vendor', {
        vendor: (state) => state.vendors,
        vendorSucccess: (state) => state.vendorSucccess,
        vendorSingleData: state => state.vendorSingleData,
        vendorExists: (state) => state.vendorExists,
        vendorErr: (state) => state.vendorErr,
        vendorDeleteStatus: (state) => state.vendorDeleteStatus,
        vendorsDownload: (state) => state.vendorsDownload,
    })
}
export const vendorMethods = mapActions('vendor', ['fetchVendors',
    'postVendor',
    'putVendor',
    'deleteVendor',
    'fetchSingleVendors',
    'clearVendor',
    'isVendorExists',
    'clearIsVendorExist',
    'exportVendorRecord',
    'clearDeleteVendor'
]);

// io Section
export const IOComputed = {
    ...mapState('io', {
        ioList: (state) => state.ioList,
        advertiserList: state => state.advertiserList,
        ioSubmitSuccess: state => state.ioSubmitSuccess,
        ioSubmitError: state => state.ioSubmitError,
        ioCommentsList: state => state.ioCommentsList,
        ioCommentsResponse: state => state.ioCommentsResponse,
        ioSingleResponse: state => state.ioSingleResponse,
        ioDeleteStatus: state => state.ioDeleteStatus,
        buyingList: state => state.buyingList,
        manufacturerList: state => state.manufacturerList,
        
        countyList: state => state.countyList,
        stateList: state => state.stateList,
        dmaList: state => state.dmaList,
        congressList: state => state.congressList,
        envelopeStatus: state => state.envelopeStatus,
        ioCampaignSuccess: state => state.ioCampaignSuccess,
        isDspIdExistFlag: state => state.isDspIdExistFlag,
        zipCodeList: state => state.zipCodeList,
        campaignTypeList: state => state.campaignTypeList,
        campaignPurchaserList: state => state.campaignPurchaserList,
        campaignList: state => state.campaignList
    })
}
export const IOMethods = mapActions('io', [
    'fetchIO',
    'deleteIO',
    'fetchSingleIO',
    'fetchIOMeta',
    'saveIO',
    'generateCampaignIO',
    'updateIO',
    'clearIO',
    'fetchIOComments',
    'isDspIdExist',
    'saveIOComments',
    'fetchZipCodeList',
    'fetchCampaignTypes',
    'fetchCampaignPurchaser',
    'fetchCampaignList',
    'featchIOEnvelopeStatus'
]);

export const forecastComputed = {
    ...mapState('forecast', {
        forecastSuccess: (state) => state.forecastSuccess,
        forecastErr: (state) => state.forecastErr,
        forecastSingleData: (state) => state.forecastSingleData,
        forecastExists: state => state.forecastExists,
         })
}
export const forecastMethods = mapActions('forecast', [
    'postForecast',
    'putForecast',
    'fetchSingleForecast',
    'isForecastExists',
    'clearIsForecastExist',
    'clearForecast'])


export const proposalComputed = {
    ...mapState('proposal', {
        proposalSingleData: state => state.proposalSingleData,
        proposalSuccess: state => state.proposalSuccess,
        proposalErr: state => state.proposalErr,
        proposalExists: state => state.proposalExists,

    })
}
export const proposalMethods = mapActions('proposal', [
    'fetchSingleProposal',
    'postProposal',
    'putProposal',
    'isProposalExists',
    'clearIsProposalExist',
    'clearProposal'])



